<template>
  <div>
    <!-- <validation-observer ref="asd"> -->

    <b-form-group
      label-cols-md="4"
      :label="label"
      :description="desc"
    >

      <b-form-checkbox
        :checked="value"
        switch
        @input="handleInput"
      />
    </b-form-group>
    <!-- </validation-observer> -->
  </div>
</template>

<script>
import {BFormCheckbox, BFormGroup} from 'bootstrap-vue'
  export default {
    components: {
      BFormCheckbox,
      BFormGroup,
    },
    props: {
      value: {
        type: Boolean,
        default: true
      },
      label: {
        type: String,
        default: 'LabelName'
      },
      placeholder: {
        type: String,
        default: ''
      },
      desc: {
        type: String,
        default: ''
      },
      rules: {
        type: String,
        default: ''
      },
    },
    methods: {
      handleInput(e) {
        this.$emit('input', e)
      }
    },
    
  }
</script>

<style lang="scss" scoped>

</style>