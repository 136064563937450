<template>
  <b-card>

    <validation-observer ref="simpleRules">
      <b-form ref="baseForm">
        <b-row>
        
          <b-col cols="12">
            <form-input
              v-model="model.username"
              label="Username"
              rules="required"
            />
          </b-col>
          <b-col cols="12">
            <form-input
              v-model="model.password"
              label="Password"
              rules=""
              type="password"
            />
          </b-col>
          <b-col cols="12">
            <form-input
              v-model="model.repassword"
              label="Re-Password"
              rules="confirmed:Password"
              type="password"
            />
          </b-col>
          <b-col cols="12">
            <form-input
              v-model="model.email"
              label="Email"
              rules="required|email"
            />
          </b-col>
          <b-col cols="12">
            <form-radio
              v-model="model.contact_type"
              label="Tipe User"
              rules=""
              :options="[
                {id:'App\\Model\\Contact\\Staff',name:'Internal'},
                {id:'App\\Model\\Contact\\Vendor',name:'Penyedia/Vendor'},
              ]"
              @input="handleContactType"
            />
          </b-col>
          <!-- <b-col cols="12">
            <form-select
              v-model="model.level"
              label="Level"
              rules="required"
              :options="[{id:'super admin',name:'Super Admin'},{id:'admin regional',name:'Admin Regional'},{id:'operator',name:'Operator'}]"
            />
          </b-col> -->
          <b-col cols="12">
            <form-select-popup
              v-model="model.role"
              label="Level"
              rules="required"
              dataurl="/role"
            />
          </b-col>
          <b-col cols="12">
            <form-select-popup
              v-model="model.contact"
              label="Contact"
              rules="required"
              :dataurl="urlContact"
            />
          </b-col>
          <b-col cols="12">
            <form-switch
              v-model="model.isActive"
              label="Active"
            />
          </b-col>

          <b-col cols="12">
            <b-button
              class="mr-1"
              size="sm"
              variant="warning"
              type="button"
              @click.prevent="cancel"
            >
              Back
            </b-button>
            <b-button
              class="mr-1"
              size="sm"
              variant="primary"
              type="submit"
              :disabled="loading"
              @click.prevent="validationForm"
            >
            
              <span v-if="loading">
                <b-spinner small />
                Loading...
              </span>
              <span v-else>Submit</span>
            </b-button>
          
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import {BForm,BRow, BCol, BButton,BSpinner,BCard} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FormInput from '@/views/base/form/FormInput.vue'
import FormRadio from '@/views/base/form/FormRadio.vue'
import FormSelectPopup from '@/views/base/form/FormSelectPopup.vue'
import FormSwitch from '@/views/base/form/FormSwitch.vue'



export default {
  components: {
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BButton,
    BSpinner,
    BCard,
    FormInput,
    FormRadio,
    FormSelectPopup,
    FormSwitch,

  },

  data() {
    return {
      loading :false,
      model:{},
      urlContact:'/contact', 
      posturl:'/user',
      // validation
      required,
      email,
    }
  },
  mounted(){
    if(this.$route.params.id != null)
    this.$http.get(this.posturl+'/'+this.$route.params.id).then(res=>{
      this.model = res.data
    })
  },
  methods: {

    filterDataSubmit() {
      let newData ={}
      newData.email = this.model.email
      newData.username = this.model.username
      newData.password = this.model.password
      // newData.level = this.model.level
      newData.contact_id = this.model.contact.id
      newData.contact_type = this.model.contact_type
      newData.role_id = this.model.role.id
      newData.is_active = this.model.isActive

      newData.name = this.model.contact.name

      return newData
    },

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loading = true
          let paramData = this.filterDataSubmit()
          if(this.$route.params.id == null){
            this.$http.post(this.posturl,paramData)
            .then(()=>{
              this.successSubmit()
            })
            .catch(err=>{
              this.errorSubmit(err)
            })
            .finally(() => {this.loading = false})       
          }else{
            this.$http.put(this.posturl+'/'+this.model['id'],paramData)
            .then(()=>{
              this.successSubmit()
            })
            .catch(err=>{
              this.errorSubmit(err)
            })
            .finally(() => {this.loading = false})  
          }
        }else{
          this.$bvToast.toast('Please correct form input before submit', {
            title: 'Form Error',
            solid: true,
            variant:'danger'
          })
        }
      })
    },

    successSubmit(){
      this.loading = false
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Submit Success',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
      if(this.ismodal)
        this.$emit("onsubmite", true)
      else
        this.$router.go(-1)
    },
    errorSubmit(err){
      const msg = err.response.data.message
      this.$bvToast.toast((msg)?msg:'Submit error', {
        title: 'Error',
        solid: true,
        variant:'danger'
      })
    },
    cancel(){
      if(this.ismodal)
        this.$emit("onsubmite", false)
      else
        this.$router.go(-1)
    },

    handleContactType(e){
      if(e == 'App\\Model\\Contact\\Staff'){
        this.urlContact='/staff'
      }else{
        this.urlContact='/vendor'
      }
    }
  },
}
</script>
